import React from "react";
import {
  Segment,
  Container,
  Header,
  Image,
  Button,
  Icon
} from "semantic-ui-react";
import styled from "styled-components";

const BG = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  background-image: url(/assets/BG.jpg);
`;

const HomePage = ({ history }) => {
  return (
    <BG>
      <Segment textAlign='center' vertical className='masthead'>
        <Container>
          <Image
            centered
            size='medium'
            src='/assets/logo.svg'
            alt='logo'
            style={{ marginBottom: 35 }}
          />
          <Button onClick={() => history.push("/dashboard")} size='huge'>
            Dashboard
            <Icon name='right arrow' />
          </Button>
        </Container>
      </Segment>
    </BG>
  );
};

export default HomePage;
