import React from "react";

const ProductDetailedVideo = ({ product }) => {
  return (
    <div>
      {product.videoURL ? (
        <div className='video-container'>
          <iframe
            title='video'
            src={product.videoURL}
            frameBorder='0'
            className='video'
            allowFullScreen
          />
        </div>
      ) : null}
    </div>
  );
};

export default ProductDetailedVideo;
