import { createReducer } from "../../app/common/util/reducerUtils";
import {
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  FETCH_PRODUCTS
} from "./productConstants";

const initialState = [];

const createProduct = (state, payload) => {
  return [...state, payload.product];
};

const updateProduct = (state, payload) => {
  return [
    ...state.filter(product => product.id !== payload.product.id),
    payload.product
  ];
};

const deleteProduct = (state, payload) => {
  return [...state.filter(product => product.id !== payload.productId)];
};

const fetchProducts = (state, payload) => {
  return payload.products;
};

export default createReducer(initialState, {
  [CREATE_PRODUCT]: createProduct,
  [UPDATE_PRODUCT]: updateProduct,
  [DELETE_PRODUCT]: deleteProduct,
  [FETCH_PRODUCTS]: fetchProducts
});
