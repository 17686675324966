import React, { createContext, useContext, useState, useEffect } from "react";
import firebase from "../config/firebase";

const DataStoreContext = createContext();

const DataStoreProvider = ({ children }) => {
  const [enquiries, setEnquiries] = useState([]);
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("enquiries")
      .onSnapshot(handleEnquiries);
    return () => unsubscribe();
  }, []);

  function handleEnquiries(snapShot) {
    const values = snapShot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setEnquiries(values);
  }

  const [products, setProducts] = useState([]);
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("products")
      .onSnapshot(handleProducts);
    return () => unsubscribe();
  }, []);

  function handleProducts(snapShot) {
    const values = snapShot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setProducts(values);
  }

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("category")
      .onSnapshot(handleCategories);
    return () => unsubscribe();
  }, []);

  function handleCategories(snapShot) {
    const values = snapShot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setCategories(values);
  }

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("users")
      .onSnapshot(handleUsers);
    return () => unsubscribe();
  }, []);

  function handleUsers(snapShot) {
    const values = snapShot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setUsers(values);
  }

  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    const authUser = firebase.auth().currentUser;

    if (users && users.length > 0 && authUser && authUser.uid) {
      let userDoc = users.filter(
        ({ id }) => id.toString() === "xTdl4q6yPmRuoYFuQkAoj8SDJdB3"
      );
      userDoc = userDoc[0];
      setCurrentUser({ authUser, userDoc });
    }
  }, [users]);

  return (
    <DataStoreContext.Provider
      value={{ enquiries, products, categories, users, currentUser }}
    >
      {children}
    </DataStoreContext.Provider>
  );
};

function useDataStore() {
  return useContext(DataStoreContext);
}

export default DataStoreProvider;

export { useDataStore };
