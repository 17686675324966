import React, { Component } from "react";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import SignedOutMenu from "./Menus/SignedOutMenu";
import SignedInMenu from "./Menus/SignedInMenu";
import { openModal } from "../../modals/modalActions";
import styled from "styled-components";
import { Button, Icon } from "semantic-ui-react";

const Bar = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
  background-color: #fff;
  position: relative;
  height: 80px;
  @media only screen and (max-width: 600px) {
    padding: 0px;
  }

  .menuBox {
    display: grid;
    justify-content: start;
    align-content: center;
  }
  .logoBox {
    display: grid;
    justify-content: center;
    align-content: center;
    img {
      width: 180px;
      height: auto;
    }
  }
  .profileBox {
    display: grid;
    justify-content: end;
    align-content: center;

  }
`;

const mapDispatchToProps = {
  openModal
};

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile
});

class TopBar extends Component {
  handleSignIn = () => {
    this.props.openModal("LoginModal");
  };

  handleRegister = () => {
    this.props.openModal("RegisterModal");
  };

  handleSignOut = () => {
    this.props.firebase.logout();
    this.props.history.push("/");
  };

  render() {
    const { auth, profile, openMenu } = this.props;
    const authenticated = auth.isLoaded && !auth.isEmpty;

    return (
      <Bar>
        <div className='menuBox'>
          <Icon size='large' name='bars' onClick={openMenu} />
        </div>

        <div className='logoBox'>
          <img src='/assets/logo.png' alt='logo' />
        </div>
        <div className='profileBox'>
          {authenticated ? (
            <SignedInMenu signOut={this.handleSignOut} profile={profile} />
          ) : (
            <SignedOutMenu
              signIn={this.handleSignIn}
              register={this.handleRegister}
            />
          )}
        </div>
      </Bar>
    );
  }
}

export default withRouter(
  withFirebase(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(TopBar)
  )
);
