import React, { useState, useEffect, Fragment } from "react";
import { Segment, Container, Grid, Button, Image } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useDataStore } from "../../../app/store/DataStore";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const PageTitle = styled.div`
  background-color: #dee3e8;
  display: inline-block;
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
`;

const Breadcrumb = styled(Link)`
  color: #223247;
  text-transform: capitalize;
  font-size: 18px;
  padding: 5px;
`;

const Card = styled(Link)`
  background-color: #fff;
  border-radius: 8px;
  transition: box-shadow 0.3s;
  overflow: hidden;
  padding: 15px 0px 0px 0px;
  border: 1px solid #233787;
  text-align: center;
  h3 {
    background-color: #233787;
    text-align: center;
    padding: 10px;
    color: #fff;
  }
  img {
    width: 150px;
  }
  :hover {
    -webkit-box-shadow: 0px 3px 5px 0px rgba(199, 202, 207, 1);
    -moz-box-shadow: 0px 3px 5px 0px rgba(199, 202, 207, 1);
    box-shadow: 0px 3px 5px 0px rgba(199, 202, 207, 1);
  }
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

function BrochureDashboard(props) {
  const [categoriesLoaded, setCategoriesLoaded] = useState(false);
  const [allCategories, setAllCategories] = useState([]);

  const { categories, currentUser } = useDataStore();

  useEffect(() => {
    setAllCategories(categories);
    setCategoriesLoaded(true);
  }, [categories, currentUser]);

  if (categories.length !== 8) return <LoadingComponent />;
  return (
    <Fragment>
      <Container className='topPage'>
        <Grid>
          <Grid.Column width={6}>
            <Breadcrumb to={`/dashboard`}>Home</Breadcrumb>
          </Grid.Column>
          <Grid.Column width={10}>
            {currentUser && currentUser.userDoc && currentUser.userDoc.isAdmin && (
              <Button
                as={Link}
                to={`/createProduct`}
                color='orange'
                floated='right'
                className='create-button'
              >
                Create Product
              </Button>
            )}
            <Button
              as={Link}
              to='/enquire'
              floated='right'
              className='enquire-button'
              content='Enquire'
            />
          </Grid.Column>
        </Grid>
      </Container>
      <Segment loading={!categoriesLoaded} className='innerSegmentSingle'>
        <PageTitle>Catalogue</PageTitle>

        <Cards>
          {allCategories &&
            allCategories.map(category => (
              <Card key={category.id} to={`/catalogue/${category.id}`}>
                {/* <Image src={category.photoURL} centered /> */}
                <LazyLoadImage
                  alt={category.id}
                  effect='blur'
                  height='150px'
                  width='150px'
                  src={category.photoURL}
                />
                <h3>{category.title}</h3>
              </Card>
            ))}
        </Cards>
      </Segment>
    </Fragment>
  );
}

export default BrochureDashboard;
