import React, { useState, useEffect } from "react";
import { Segment, Form, Button, Header } from "semantic-ui-react";
import { reduxForm, Field } from "redux-form";
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan
} from "revalidate";
import cuid from "cuid";
import TextInput from "../../../app/common/form/TextInput";
import SelectInput from "../../../app/common/form/SelectInput";
import RadioInput from "../../../app/common/form/RadioInput";
import TextArea from "../../../app/common/form/TextArea";
import firebase from "../../../app/config/firebase";
import { useDataStore } from "../../../app/store/DataStore";
import styled from "styled-components";

const PageTitle = styled.div`
  background-color: #dee3e8;
  display: inline-block;
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
`;

const FormHeader = styled.div`
  display: block;
  border-radius: 4px;
  margin: 20px 0px;
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
  padding-bottom: 5px;
`;

const validate = combineValidators({
  title: isRequired({ message: "The event title is required" }),
  category: isRequired({ message: "The category is required" }),
  description: composeValidators(
    isRequired({ message: "Please enter a description" }),
    hasLengthGreaterThan(4)({
      message: "Description needs to be at least 5 characters"
    })
  )(),
  city: isRequired("city"),
  venue: isRequired("venue"),
  date: isRequired("date")
});

const category = [
  { key: "knees", text: "Knees", value: "knees" },
  { key: "hips", text: "Hips", value: "hips" },
  { key: "extremities", text: "Extremities", value: "extremities" },
  { key: "spine", text: "Spine", value: "spine" },
  { key: "sportsmed", text: "Sportsmed", value: "sportsmed" },
  { key: "trauma", text: "Trauma", value: "trauma" },
  { key: "surgical", text: "Surgical", value: "surgical" }
];

const information = [
  { key: "brochures", text: "Brochures", value: "brochures" },
  { key: "dataSheet", text: "Data Sheet", value: "dataSheet" },
  {
    key: "surgicalTechnique",
    text: "Surgical Technique",
    value: "surgicalTechnique"
  }
];

function EnquiryForm(props) {
  const [chosenProduct, setChosenProduct] = useState({
    titleFull: ""
  });

  const { currentUser, products } = useDataStore();

  const productId = props.match.params.productId;

  useEffect(() => {
    if (products.length > 0) {
      const chosenProduct = products.filter(({ id }) => id === productId);
      setChosenProduct(chosenProduct[0]);
    }
  }, [products, productId]);

  const onFormSubmit = values => {
    if (chosenProduct && chosenProduct.titleFull) {
      firebase
        .firestore()
        .collection("enquiries")
        .add({
          ...values,
          id: cuid(),
          userUid: currentUser.userDoc.id,
          enquiryMain: chosenProduct.titleFull,
          enquiryDate: new Date(),
          createdBy: currentUser.authUser.displayName
        });
    } else {
      firebase
        .firestore()
        .collection("enquiries")
        .add({
          ...values,
          id: cuid(),
          userUid: currentUser.userDoc.id,
          enquiryDate: new Date(),
          createdBy: currentUser.authUser.displayName
        });
    }

    props.history.push(`/catalogue`);
  };

  const { history, invalid, submitting, pristine } = props;
  return (
    <Segment className='innerSegment'>
      <PageTitle>Enquire</PageTitle>

      <Form onSubmit={props.handleSubmit(onFormSubmit)} autoComplete='off'>
        <FormHeader>Your Details</FormHeader>
        <Form.Group widths='equal'>
          <Field
            name='nameFirst'
            component={TextInput}
            // placeholder='First Name'
            label='First Name'
          />
          <Field
            name='nameLast'
            component={TextInput}
            // placeholder='Last Name'
            label='Last Name'
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Field
            name='email'
            component={TextInput}
            // placeholder='Email'
            label='Email Address'
          />
          <Field
            name='phone'
            component={TextInput}
            // placeholder='Phone'
            label='Phone Number'
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Field
            name='location'
            component={TextInput}
            label='Your location'
            // placeholder='Your location'
          />
        </Form.Group>
        <Form.Group inline>
          <label>Where do you work?</label>
          <Field
            name='placeOfWork'
            type='radio'
            value='hospital'
            label='Hospital'
            component={RadioInput}
          />
          <Field
            name='placeOfWork'
            type='radio'
            value='practice'
            label='Practice'
            component={RadioInput}
          />
        </Form.Group>
        <FormHeader>Enquiry Details</FormHeader>
        {chosenProduct && chosenProduct.titleFull && (
          <Field
            name='enquiryMain'
            component={TextInput}
            placeholder='Chosen enquiry'
            defaultValue={
              chosenProduct && chosenProduct.titleFull
                ? chosenProduct.titleFull
                : ""
            }
          />
        )}
        <Field
          name='enquiryCategory'
          component={SelectInput}
          options={category}
          multiple={true}
          label='What information would you like more of?'
          placeholder='Select'
        />
        <Field
          name='enquiryInformation'
          component={SelectInput}
          options={information}
          multiple={true}
          placeholder='Select'
          label='What would you like to receive?'
        />
        <Form.Group widths='equal'>
          <Field
            name='comments'
            component={TextArea}
            // placeholder='Comments'
            label='Comments'
          />
        </Form.Group>
        <Button
          disabled={(invalid, submitting, pristine)}
          positive
          type='submit'
        >
          Submit
        </Button>
        <Button onClick={() => history.push(`/catalogue`)} type='button'>
          Cancel
        </Button>
      </Form>
    </Segment>
  );
}

export default reduxForm({ form: "productEnquiryForm", validate })(EnquiryForm);
