import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCgQfLWdj1vXJB6A5CjkwnTbChtCS9F0cI",
  authDomain: "zimmer-biomet-call-planner.firebaseapp.com",
  databaseURL: "https://zimmer-biomet-call-planner.firebaseio.com",
  projectId: "zimmer-biomet-call-planner",
  storageBucket: "zimmer-biomet-call-planner.appspot.com",
  messagingSenderId: "381599481806",
  appId: "1:381599481806:web:d46f6fea622d9d43"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;
