import React from "react";
import { Menu, Image, Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const MenuSection = styled.div`
  padding: 0px;
  margin: 0px;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const SignedInMenu = ({ signOut, profile }) => {
  return (
    <MenuSection>
      <Menu.Item position='right'>
        <Image
          avatar
          spaced='right'
          src={profile.photoURL || "/assets/user.png"}
        />
        <Dropdown pointing='top left' text={profile.displayName}>
          <Dropdown.Menu>
            {/* <Dropdown.Item text='Create Event' icon='plus' />
          <Dropdown.Item text='My Events' icon='calendar' />
          <Dropdown.Item text='My Network' icon='users' />
          <Dropdown.Item text='My Profile' icon='user' /> */}
            <Dropdown.Item
              as={Link}
              to='/settings'
              text='Settings'
              icon='settings'
            />
            <Dropdown.Item onClick={signOut} text='Sign Out' icon='power' />
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </MenuSection>
  );
};

export default SignedInMenu;
