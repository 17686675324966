import React, { useState, useEffect } from "react";
import {
  Segment,
  Form,
  Button,
  Dropdown,
  Grid,
  Header,
  Image
} from "semantic-ui-react";

const Brochures = ({ initialValues, setInitialValues }) => {
  const updateTitleChanged = index => e => {
    let newArr = [...initialValues.brochures];
    newArr[index].title = e.target.value;
    setInitialValues({ ...initialValues, brochures: newArr });
  };

  const updateLinkChanged = index => e => {
    let newArr = [...initialValues.brochures];
    newArr[index].pdfURL = e.target.value;
    setInitialValues({ ...initialValues, brochures: newArr });
  };

  return initialValues.brochures.map((brochure, index) => {
    return (
      <Grid key={index}>
        <Grid.Column width='7'>
          <Form.Field>
            <label>Title</label>
            <input
              type='text'
              name={`pdf${index}.title`}
              value={brochure.title ? brochure.title : ""}
              onChange={updateTitleChanged(index)}
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column width='7'>
          <Form.Field>
            <label>PDF Link</label>
            <input
              type='text'
              name={`pdf${index}.pdfURL`}
              value={brochure.pdfURL ? brochure.pdfURL : ""}
              onChange={updateLinkChanged(index)}
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column width='2'>
          <Button
            negative
            type='button'
            onClick={() => {
              const brochuresArray = initialValues.brochures;
              brochuresArray.splice(index, 1);
              setInitialValues({
                ...initialValues,
                brochures: brochuresArray
              });
            }}
          >
            X
          </Button>
        </Grid.Column>
      </Grid>
    );
  });
};

export default Brochures;
