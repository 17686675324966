import React from "react";
import { Form, Label, Select } from "semantic-ui-react";

const SelectInput = ({
  input,
  type,
  placeholder,
  label,
  multiple,
  options,
  defaultValue,
  meta: { touched, error }
}) => {
  let newValue = null;
  if (input.value) {
    newValue = input.value;
  } else if (defaultValue) {
    newValue = defaultValue;
  }
  return (
    <Form.Field error={touched && !!error}>
      <label>{label}</label>
      <Select
        value={newValue}
        onChange={(e, data) => input.onChange(data.value)}
        placeholder={placeholder}
        options={options}
        multiple={multiple}
      />
      {touched && error && (
        <Label basic color='red'>
          {error}
        </Label>
      )}
    </Form.Field>
  );
};

export default SelectInput;
