import React, { useState, useEffect } from "react";
import {
  Segment,
  Form,
  Button,
  Dropdown,
  Grid,
  Header,
  Image
} from "semantic-ui-react";

import DropzoneInput from "../../../app/common/form/DropzoneInput";
import CropperInput from "../../../app/common/form/CropperInput";
import firebase from "../../../app/config/firebase";
import { toastr } from "react-redux-toastr";
import { useDataStore } from "../../../app/store/DataStore";
import styled from "styled-components";
import Brochures from "./Brochures";

const solution = [
  { key: "knees", text: "Knees", value: "knees" },
  { key: "hips", text: "Hips", value: "hips" },
  { key: "extremities", text: "Extremities", value: "extremities" },
  { key: "spine", text: "Spine", value: "spine" },
  { key: "sportsmed", text: "Sportsmed", value: "sportsmed" },
  { key: "trauma", text: "Trauma", value: "trauma" },
  { key: "surgical", text: "Surgical", value: "surgical" },
  { key: "cement", text: "Cement", value: "cement" }
];

const type = [
  { key: "primary", text: "Primary", value: "primary" },
  { key: "revision", text: "Revision", value: "revision" },
  { key: "sportsmed", text: "Sportsmed", value: "sportsmed" },
  { key: "cement", text: "Cement", value: "cement" }
];

function ProductForm(props) {
  const [files, setFiles] = useState([]);
  const [image, setImage] = useState([null]);
  const [initialValues, setInitialValues] = useState({
    title: "",
    titleFull: "",
    category: "",
    type: "",
    description: "",
    photoURL: "",
    videoURL: "",
    brochures: [
      {
        title: "",
        pdfURL: ""
      }
    ],
    surgicalTechniques: []
  });

  // Get products from context
  const { products } = useDataStore();

  // Get productId from url
  const productId = props.match.params.productId;

  // Filter product & set initialValues
  useEffect(() => {
    if (products.length > 0 && productId) {
      const filteredProduct = products.filter(({ id }) => id === productId);
      setInitialValues(filteredProduct[0]);
    }
  }, [products, productId]);

  const addBrochure = brochure => {
    const brochuresArray = initialValues.brochures;
    brochuresArray.push(brochure);
    setInitialValues({
      ...initialValues,
      brochures: brochuresArray
      // brochures: [...initialValues.brochures, brochure]
    });
  };

  const removeBrochure = index => {
    console.log("INDEX", index);
    // const brochuresArray = initialValues.brochures;
    // brochuresArray.splice(index, 1);
    // setInitialValues({
    //   ...initialValues,
    //   brochures: brochuresArray
    // });
  };

  const { history, invalid, submitting } = props;

  useEffect(() => {
    return () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  const deleteTheProduct = async productId => {
    try {
      firebase
        .firestore()
        .collection("products")
        .doc(productId)
        .delete();
      if (initialValues && initialValues.photoURL) {
        // Create a reference to the file to delete
        var desertRef = firebase.storage().refFromURL(initialValues.photoURL);

        // Delete the file
        desertRef
          .delete()
          .then(function() {
            // File deleted successfully
          })
          .catch(function(error) {
            // Uh-oh, an error occurred!
          });
      }

      toastr.success("Success");
      history.push(`/catalogue`);
    } catch (error) {
      console.log(error);
      toastr.error("Computer says no");
    }
  };
  console.log("INITIAL VALUES", initialValues);
  console.log("FILES", files);

  const onFormSubmit = async () => {
    console.log("THE VALUES", initialValues);
    if (initialValues && productId) {
      try {
        // const newDownloadURL = await uploadImageToFirestore(
        //   files[0].name,
        //   image
        // );

        firebase
          .firestore()
          .collection("products")
          .doc(productId)
          .set(
            {
              ...initialValues
              // photoURL: newDownloadURL
            },
            { merge: true }
          );

        toastr.success("Success!", "Product has been updated");
        history.push(`/catalogue`);
      } catch (error) {
        console.error(error);
        toastr.error("Oops", "Something went wrong");
      }
    } else {
      try {
        const downloadURL = await uploadImageToFirestore(files[0].name, image);
        firebase
          .firestore()
          .collection("products")
          .add({
            ...initialValues,
            photoURL: downloadURL
          });
        history.push(`/catalogue`);
        toastr.success("Success!", "Product has been created");
      } catch (error) {
        console.error(error);
        toastr.error("Oops", "Something went wrong");
      }
    }
  };

  // const updateTitleChanged = index => e => {
  //   let newArr = [...initialValues.brochures];
  //   newArr[index].title = e.target.value;
  //   setInitialValues({ ...initialValues, brochures: newArr });
  // };

  // const updateLinkChanged = index => e => {
  //   let newArr = [...initialValues.brochures];
  //   newArr[index].pdfURL = e.target.value;
  //   setInitialValues({ ...initialValues, brochures: newArr });
  // };

  // const Brochures = () => {
  //   return initialValues.brochures.map((brochure, index) => {
  //     return (
  //       <Grid key={index}>
  //         <Grid.Column width='7'>
  //           <Form.Field>
  //             <label>Brochure Title</label>
  //             <input
  //               type='text'
  //               name={`pdf${index}.title`}
  //               value={brochure.title ? brochure.title : ""}
  //               onChange={updateTitleChanged(index)}
  //             />
  //           </Form.Field>
  //         </Grid.Column>
  //         <Grid.Column width='7'>
  //           <Form.Field>
  //             <label>Brochure PDF Link</label>
  //             <input
  //               type='text'
  //               name={`pdf${index}.pdfURL`}
  //               value={brochure.pdfURL ? brochure.pdfURL : ""}
  //               onChange={updateLinkChanged(index)}
  //             />
  //           </Form.Field>
  //         </Grid.Column>
  //         <Grid.Column width='2'>
  //           <Button
  //             negative
  //             type='button'
  //             onClick={() => {
  //               const brochuresArray = initialValues.brochures;
  //               brochuresArray.splice(index, 1);
  //               setInitialValues({
  //                 ...initialValues,
  //                 brochures: brochuresArray
  //               });
  //             }}
  //           >
  //             X
  //           </Button>
  //         </Grid.Column>
  //       </Grid>
  //     );
  //   });
  // };

  const uploadImageToFirestore = async (fileName, image) => {
    const path = `images/productImages/${fileName}`;
    // upload the file to firebase storage
    let uploadedFile = await firebase
      .storage()
      .ref(path)
      .put(image);
    // get url of the image
    let downloadURL = await uploadedFile.ref.getDownloadURL();
    return downloadURL;
  };

  // const renderBrochure = ({
  //   addBrochure,
  //   initialValues,
  //   meta: { error, submitFailed }
  // }) => (
  //   <BrochureList>
  //     <li>
  //       <Button
  //         type='button'
  //         onClick={() => addBrochure({ title: "", pdfURL: "" })}
  //       >
  //         Add Brochure
  //       </Button>
  //       {submitFailed && error && <span>{error}</span>}
  //     </li>
  //     {initialValues.map((item, index) => (
  //       <li key={index}>
  //         <Form.Group widths='equal'>
  //           <Field
  //             name={`${index}.title`}
  //             type='text'
  //             component={TextInput}
  //             defaultValue={item.title ? item.title : ""}
  //             label='Brochure Title'
  //           />
  //           <Field
  //             name={`${index}.pdfURL`}
  //             type='text'
  //             component={TextInput}
  //             label='Brochure URL'
  //             defaultValue={item.pdfURL ? item.pdfURL : ""}
  //           />
  //           <Button
  //             negative
  //             type='button'
  //             onClick={() => initialValues.splice(index, 1)}
  //           >
  //             X
  //           </Button>
  //         </Form.Group>
  //       </li>
  //     ))}
  //   </BrochureList>
  // );

  return (
    <Segment className='innerSegment'>
      <PageTitle>Edit Product</PageTitle>
      <Form onSubmit={onFormSubmit} autoComplete='off'>
        <FormHeader>Product Details</FormHeader>
        <Form.Field>
          <label>What is the product title?</label>
          <input
            type='text'
            name='title'
            value={initialValues.title ? initialValues.title : ""}
            onChange={event =>
              setInitialValues({ ...initialValues, title: event.target.value })
            }
          />
        </Form.Field>
        <Form.Field>
          <label>What is the product full title?</label>
          <input
            type='text'
            name='titleFull'
            value={initialValues.titleFull ? initialValues.titleFull : ""}
            onChange={event =>
              setInitialValues({
                ...initialValues,
                titleFull: event.target.value
              })
            }
          />
        </Form.Field>
        <Form.Field>
          <Dropdown
            placeholder='What is the category solution?'
            options={solution}
            name='category'
            fluid
            selection
            value={initialValues.category ? initialValues.category : ""}
            onChange={(event, data) => {
              setInitialValues({
                ...initialValues,
                category: data.value
              });
            }}
          />
        </Form.Field>
        <Form.Field>
          <Dropdown
            placeholder='What is the product type?'
            options={type}
            fluid
            selection
            value={initialValues.type ? initialValues.type : ""}
            onChange={(event, data) => {
              setInitialValues({
                ...initialValues,
                type: data.value
              });
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>What is the product description?</label>
          <textarea
            type='text'
            name='description'
            value={initialValues.description ? initialValues.description : ""}
            onChange={event =>
              setInitialValues({
                ...initialValues,
                description: event.target.value
              })
            }
          />
        </Form.Field>

        <PhotoSection>
          <FormHeader>Product Media</FormHeader>
          <Grid>
            <Grid.Column width={4}>
              <Header color='teal' sub content='Step 1 - Add Photo' />
              <DropzoneInput setFiles={setFiles} />
            </Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={4}>
              <Header sub color='teal' content='Step 2 - Resize image' />
              {files.length > 0 && (
                <CropperInput
                  setImage={setImage}
                  imagePreview={files[0].preview}
                />
              )}
            </Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={4}>
              <Header sub color='teal' content='Step 3 - Preview & Upload' />
              {files[0] && files[0].preview ? (
                <div
                  className='img-preview'
                  style={{
                    minHeight: "200px",
                    minWidth: "200px",
                    overflow: "hidden"
                  }}
                />
              ) : (
                <Image src={initialValues.photoURL} />
              )}
            </Grid.Column>
          </Grid>
        </PhotoSection>
        <Form.Field>
          <label>Enter the product video URL</label>
          <input
            type='text'
            name='videoURL'
            value={initialValues.videoURL ? initialValues.videoURL : ""}
            onChange={event =>
              setInitialValues({
                ...initialValues,
                videoURL: event.target.value
              })
            }
          />
        </Form.Field>

        <FormHeader>Learning Materials</FormHeader>
        <Brochures
          initialValues={initialValues}
          setInitialValues={setInitialValues}
        />
        <BtnBox>
          <Button
            type='button'
            onClick={() => addBrochure({ title: "", pdfURL: "" })}
          >
            Add Document
          </Button>
        </BtnBox>

        <Button disabled={(invalid, submitting)} positive type='submit'>
          Submit
        </Button>
        <Button type='button' onClick={() => deleteTheProduct(productId)}>
          Delete
        </Button>
      </Form>
    </Segment>
  );
}

export default ProductForm;

const BtnBox = styled.div`
  margin: 20px 0px;
`;

const PageTitle = styled.div`
  background-color: #dee3e8;
  display: inline-block;
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
`;

const PhotoSection = styled.div`
  margin-bottom: 40px;
`;

const FormHeader = styled.div`
  display: block;
  border-radius: 4px;
  margin: 20px 0px;
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
  padding-bottom: 5px;
`;
