import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useDataStore } from "../../../app/store/DataStore";
import { Segment, Container, Grid, Button } from "semantic-ui-react";

const Breadcrumb = styled(Link)`
  color: #223247;
  text-transform: capitalize;
  font-size: 18px;
  padding: 5px;
`;

const PageTitle = styled.div`
  background-color: #dee3e8;
  display: inline-block;
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
`;

const PageDescription = styled.div`
  font-size: 17px;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const Card = styled(Link)`
  background-color: #ecececf7;
  border-radius: 8px;
  transition: box-shadow 0.3s;
  overflow: hidden;
  padding: 0px 0px 0px 0px;
  h3 {
    background-color: #233787;
    text-align: center;
    padding: 10px;
    color: #fff;
  }
  img {
    width: 150px;
  }
  :hover {
    -webkit-box-shadow: 0px 3px 5px 0px rgba(199, 202, 207, 1);
    -moz-box-shadow: 0px 3px 5px 0px rgba(199, 202, 207, 1);
    box-shadow: 0px 3px 5px 0px rgba(199, 202, 207, 1);
  }
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

function TypeList(props) {
  const [currentCategory, setCurrentCategory] = useState({});

  const { categories } = useDataStore();

  const solutionId = props.match.params.solutionId;

  useEffect(() => {
    if (categories.length > 0) {
      const currentCategory = categories.filter(({ id }) => id === solutionId);
      setCurrentCategory(currentCategory[0]);
    }
  }, [categories, solutionId]);

  return (
    <Fragment>
      <Container className='topPage'>
        <Grid>
          <Grid.Column width={9}>
            <Breadcrumb to={`/dashboard`}>Home</Breadcrumb> >{" "}
            <Breadcrumb to={`/catalogue`}>Catalogue</Breadcrumb>
          </Grid.Column>
          <Grid.Column width={7}>
            <Button
              as={Link}
              to='/enquire'
              floated='right'
              positive
              content='Enquire'
              className='enquire-button'
            />
          </Grid.Column>
        </Grid>
      </Container>
      <Segment className='innerSegmentSingle'>
        <PageTitle>{solutionId}</PageTitle>
        <PageDescription>{currentCategory.description}</PageDescription>
        <Cards>
          {solutionId === "sportsmed" ? (
            <Card to={`/catalogue/${solutionId}/sportsmed`}>
              <h3>Sportsmed</h3>
            </Card>
          ) : solutionId === "cement" ? (
            <Card to={`/catalogue/${solutionId}/cement`}>
              <h3>Cement</h3>
            </Card>
          ) : (
            <Fragment>
              <Card to={`/catalogue/${solutionId}/primary`}>
                <h3>Primary</h3>
              </Card>
              <Card to={`/catalogue/${solutionId}/revision`}>
                <h3>Revision</h3>
              </Card>
            </Fragment>
          )}
        </Cards>
      </Segment>
    </Fragment>
  );
}

export default TypeList;
