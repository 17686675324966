import React, { Fragment, useState, useEffect } from "react";
import { Grid, Segment, Button, Container } from "semantic-ui-react";
import ProductDetailedInfo from "./ProductDetailedInfo";
import ProductDetailedImage from "./ProductDetailedImage";
import ProductDetailedVideo from "./ProductDetailedVideo";
import ProductDetailedFiles from "./ProductDetailedFiles";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useDataStore } from "../../../app/store/DataStore";

const Breadcrumb = styled(Link)`
  color: #223247;
  text-transform: capitalize;
  font-size: 18px;
  padding: 5px;
`;

const PageTitle = styled.div`
  background-image: url(/assets/header-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  h3 {
    font-size: 16px;
    color: #fff;
    padding: 10px 15px;
    border-radius: 4px;
    display: inline-block;
    margin-bottom: 10px;
  }
`;

const ProductPage = styled.div`
  display: grid;
  grid-template-columns: 4fr 8fr;
  grid-gap: 20px;
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`;

function ProductDetailedPage(props) {
  const [productLoaded, setProductLoaded] = useState(false);
  const [filteredProduct, setFilteredProduct] = useState([]);

  const { products, currentUser } = useDataStore();

  const solutionId = props.match.params.solutionId;
  const typeId = props.match.params.typeId;
  const productId = props.match.params.productId;

  console.log(currentUser);

  useEffect(() => {
    if (products.length > 0) {
      const filteredProduct = products.filter(({ id }) => id === productId);
      setFilteredProduct(filteredProduct[0]);
      setProductLoaded(true);
    }
  }, [products, productId, currentUser]);

  return (
    <Fragment>
      <Container className='topPage'>
        <Grid>
          <Grid.Column width={9}>
            <Breadcrumb to={`/dashboard`}>Home</Breadcrumb> >{" "}
            <Breadcrumb to={`/catalogue`}>Catalogue</Breadcrumb> >{" "}
            <Breadcrumb to={`/catalogue/${solutionId}`}>
              {solutionId}
            </Breadcrumb>{" "}
            >{" "}
            <Breadcrumb to={`/catalogue/${solutionId}/${typeId}`}>
              {typeId}
            </Breadcrumb>
          </Grid.Column>
          <Grid.Column width={7}>
            {currentUser && currentUser.userDoc && currentUser.userDoc.isAdmin && (
              <Button
                as={Link}
                to={`/manage/${filteredProduct.id}`}
                color='orange'
                floated='right'
                className='create-button'
              >
                Edit Product
              </Button>
            )}
            <Button
              as={Link}
              to={`/enquire/${productId}`}
              floated='right'
              positive
              content='Enquire'
              className='enquire-button'
            />
          </Grid.Column>
        </Grid>
      </Container>
      <Segment loading={!productLoaded} className='innerSegmentSingle'>
        <ProductPage>
          <div className='leftCol'>
            <PageTitle>
              <h3>{filteredProduct.titleFull}</h3>
            </PageTitle>
            <ProductDetailedImage product={filteredProduct} />
            <ProductDetailedInfo product={filteredProduct} />
          </div>
          <div className='rightCol'>
            <ProductDetailedVideo product={filteredProduct} />
            <ProductDetailedFiles product={filteredProduct} />
          </div>
        </ProductPage>
      </Segment>
    </Fragment>
  );
}

export default ProductDetailedPage;
