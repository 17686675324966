import React, { Component, Fragment } from "react";
import { Icon, Menu, Sidebar, Container } from "semantic-ui-react";
import TopBar from "../../features/nav/TopBar/TopBar";
import BrochureDashboard from "../../features/brochure/BrochureDashboard/BrochureDashboard";
import { Route, Switch } from "react-router-dom";
import Homepage from "../../features/home/HomePage";
import SettingsDashboard from "../../features/user/Settings/SettingsDashboard";
import TypeList from "../../features/brochure/ProductList/TypeList";
import ProductList from "../../features/brochure/ProductList/ProductList";
import TestComponent from "../../features/testarea/TestComponent";
import ProductDetailedPage from "../../features/brochure/ProductDetailed/ProductDetailedPage";
import ProductForm from "../../features/brochure/ProductForm/ProductForm";
import ModalManager from "../../features/modals/ModalManager";
import ProductEnquiryForm from "../../features/brochure/ProductForm/ProductEnquiryForm";
import { UserIsAuthenticated } from "../../features/auth/authWrapper";
import ViewEnquiries from "../../features/brochure/Enquiries/ViewEnquiries";
import Dashboard from "../../features/dashboard/Dashboard";
import styled from "styled-components";
import { Link } from "react-router-dom";

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 1fr;
  height: 100vh;
`;
const MainContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100vh;
`;

const MenuHeader = styled.div`
  height: 80px;
  display: grid;
  align-content: center;
  border-bottom: 1px solid;
  font-size: 18px;
  i {
    display: inline-block;
  }
  span {
    display: inline-block;
  }
`;

class App extends Component {
  state = {
    visible: false
  };

  handleShowClick = () => this.setState({ visible: !this.state.visible });
  handleSidebarHide = () => this.setState({ visible: false });

  render() {
    const { visible } = this.state;
    return (
      <Fragment>
        <ModalManager />
        <Route exact path='/' component={Homepage} />
        <Route
          path='/(.+)'
          render={() => (
            <Sidebar.Pushable as='div'>
              <MainContainer className='mainContainer'>
                <Container className='top'>
                  <TopBar openMenu={this.handleShowClick} />
                </Container>

                <Sidebar
                  as={Menu}
                  animation='overlay'
                  icon='labeled'
                  // inverted
                  onHide={this.handleSidebarHide}
                  vertical
                  visible={visible}
                  width='thin'
                >
                  <MenuHeader onClick={this.handleSidebarHide}>
                    <span>
                      {" "}
                      <Icon name='arrow left' />
                      Menu
                    </span>
                  </MenuHeader>

                  <Menu.Item
                    as={Link}
                    to='/dashboard'
                    onClick={this.handleSidebarHide}
                  >
                    <Icon name='home' />
                    Home
                  </Menu.Item>
                  <Menu.Item
                    as={Link}
                    to='/catalogue'
                    onClick={this.handleSidebarHide}
                  >
                    <Icon name='file alternate outline' />
                    Catalogue
                  </Menu.Item>
                  <Menu.Item
                    as={Link}
                    to='/enquiries'
                    onClick={this.handleSidebarHide}
                  >
                    <Icon name='list alternate outline' />
                    Enquiries
                  </Menu.Item>
                </Sidebar>
                <Sidebar.Pusher dimmed={visible}>
                  <MainContent className='mainContent'>
                    <Container className='main'>
                      <Switch>
                        <Route
                          exact
                          path='/dashboard'
                          component={UserIsAuthenticated(Dashboard)}
                        />
                        <Route
                          exact
                          path='/catalogue'
                          component={UserIsAuthenticated(BrochureDashboard)}
                        />
                        <Route
                          exact
                          path='/catalogue/:solutionId'
                          component={UserIsAuthenticated(TypeList)}
                        />
                        <Route
                          exact
                          path='/catalogue/:solutionId/:typeId'
                          component={UserIsAuthenticated(ProductList)}
                        />
                        <Route
                          path='/catalogue/:solutionId/:typeId/:productId'
                          component={UserIsAuthenticated(ProductDetailedPage)}
                        />
                        <Route
                          path='/enquiries'
                          component={UserIsAuthenticated(ViewEnquiries)}
                        />
                        <Route
                          path='/createProduct'
                          component={UserIsAuthenticated(ProductForm)}
                        />
                        <Route
                          path={["/createProduct", "/manage/:productId"]}
                          component={UserIsAuthenticated(ProductForm)}
                        />
                        <Route
                          exact
                          path='/enquire'
                          component={UserIsAuthenticated(ProductEnquiryForm)}
                        />
                        <Route
                          path='/enquire/:productId'
                          component={UserIsAuthenticated(ProductEnquiryForm)}
                        />
                        <Route
                          path='/settings'
                          component={UserIsAuthenticated(SettingsDashboard)}
                        />
                        <Route path='/test' component={TestComponent} />
                      </Switch>
                    </Container>
                  </MainContent>
                </Sidebar.Pusher>
              </MainContainer>
            </Sidebar.Pushable>
          )}
        />
      </Fragment>
    );
  }
}

export default App;
