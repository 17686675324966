import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Image, Segment, Container, Grid, Button } from "semantic-ui-react";
import { useDataStore } from "../../../app/store/DataStore";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Breadcrumb = styled(Link)`
  color: #223247;
  text-transform: capitalize;
  font-size: 18px;
  padding: 5px;
`;

const PageTitle = styled.div`
  background-color: #dee3e8;
  display: inline-block;
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
`;

const Card = styled(Link)`
  background-color: #fff;
  border-radius: 8px;
  transition: box-shadow 0.3s;
  overflow: hidden;
  padding: 15px 0px 0px 0px;
  text-align: center;
  h3 {
    background-color: #233787;
    text-align: center;
    padding: 10px;
    color: #fff;
  }
  img {
    width: 150px;
  }
  :hover {
    -webkit-box-shadow: 0px 3px 5px 0px rgba(199, 202, 207, 1);
    -moz-box-shadow: 0px 3px 5px 0px rgba(199, 202, 207, 1);
    box-shadow: 0px 3px 5px 0px rgba(199, 202, 207, 1);
  }
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

function ProductList(props) {
  const [productsLoaded, setProductsLoaded] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const { products } = useDataStore();

  const solutionId = props.match.params.solutionId;
  const typeId = props.match.params.typeId;

  useEffect(() => {
    if (products) {
      const filteredProducts = products.filter(
        product => product.type === typeId && product.category === solutionId
      );
      setFilteredProducts(filteredProducts);
      setProductsLoaded(true);
    }
  }, [products, solutionId, typeId]);

  return (
    <Fragment>
      <Container className='topPage'>
        <Grid>
          <Grid.Column width={9}>
            <Breadcrumb to={`/dashboard`}>Home</Breadcrumb> >{" "}
            <Breadcrumb to={`/catalogue`}>Catalogue</Breadcrumb> >{" "}
            <Breadcrumb to={`/catalogue/${solutionId}`}>
              {solutionId}
            </Breadcrumb>
          </Grid.Column>
          <Grid.Column width={7}>
            <Button
              as={Link}
              to='/enquire'
              floated='right'
              positive
              content='Enquire'
              className='enquire-button'
            />
          </Grid.Column>
        </Grid>
      </Container>
      <Segment loading={!productsLoaded} className='innerSegmentSingle'>
        <PageTitle>
          {typeId} {solutionId} Products
        </PageTitle>
        <Cards>
          {filteredProducts.map(product => (
            <Card
              key={product.id}
              to={`/catalogue/${solutionId}/${typeId}/${product.id}`}
            >
              <LazyLoadImage
                alt={product.id}
                effect='blur'
                height='150px'
                width='150px'
                src={product.photoURL}
              />
              <h3>{product.title}</h3>
            </Card>
          ))}
        </Cards>
      </Segment>
    </Fragment>
  );
}

export default ProductList;
