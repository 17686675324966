import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { connect } from "react-redux";
import { Segment } from "semantic-ui-react";
import cuid from "cuid";
import DownloadReport from "./DownloadReport";
import format from "date-fns/format";
import styled from "styled-components";
import { useDataStore } from "../../../app/store/DataStore";

const PageTitle = styled.div`
  background-color: #dee3e8;
  display: inline-block;
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  width: 108px;
`;

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  .download-btn {
    justify-self: end;
    align-content: center;
    background-color: #dee3e8;
    margin-bottom: 20px;
    padding: 10px 20px;
    border-radius: 4px;
  }
`;

function ViewEnquiries(props) {
  const [reportsLoaded, setReportsLoaded] = useState(false);
  const [filteredEnquiries, setFilteredEnquiries] = useState([]);

  const { enquiries, users } = useDataStore();

  const { user, auth } = props;

  useEffect(() => {
    const { uid } = auth;
    let user = undefined;

    if (users) {
      user = users.filter(({ id }) => id === uid);
      user = user[0];
    }

    if (user) {
      const { isAdmin } = user;
      if (isAdmin) {
        setFilteredEnquiries(enquiries);
        setReportsLoaded(true);
      } else {
        const filteredEnquiries = enquiries.filter(
          enquiry => enquiry.userUid === uid
        );
        setFilteredEnquiries(filteredEnquiries);
        setReportsLoaded(true);
      }
    }
  }, [auth, users, enquiries]);

  let data = [];

  if (filteredEnquiries) {
    data = filteredEnquiries.map(
      ({
        nameFirst,
        nameLast,
        email,
        phone,
        placeOfWork,
        location,
        comments,
        enquiryCategory,
        enquiryInformation,
        enquiryMain,
        enquiryDate,
        createdBy
      }) => {
        return {
          name: nameFirst + " " + nameLast,
          email,
          phone,
          placeOfWork,
          location,
          comments,
          enquiryCategory,
          enquiryInformation,
          enquiryMain,
          enquiryDate: format(enquiryDate.toDate(), "yyyy-MM-dd"),
          createdBy
        };
      }
    );
  }

  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Email", accessor: "email" },
    { Header: "Phone", accessor: "phone" },
    { Header: "Place Of Work", accessor: "placeOfWork" },
    { Header: "Location", accessor: "location" },
    { Header: "Comments", accessor: "comments" },
    {
      Header: "Enquiry Categories",
      accessor: "enquiryCategory",
      Cell: ({ value }) => {
        if (value) {
          return value && value.map(cat => <div key={cuid()}>{cat}</div>);
        } else {
          return "";
        }
      }
    },
    {
      Header: "Enquiry Information",
      accessor: "enquiryInformation",
      Cell: ({ value }) => {
        if (value) {
          return value.map(info => <div key={cuid()}>{info}</div>);
        } else {
          return "";
        }
      }
    },
    {
      Header: "Main Interest",
      accessor: "enquiryMain"
    },
    {
      Header: "Enquiry Date",
      accessor: "enquiryDate"
    },
    { Header: "Created By", accessor: "createdBy" }
  ];
  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Place Of Work", key: "placeOfWork" },
    { label: "Location", key: "location" },
    { label: "Comments", key: "comments" },
    { label: "Enquiry Categories", key: "enquiryCategory" },
    { label: "Enquiry Information", key: "enquiryInformation" },
    { label: "Main Interest", key: "enquiryMain" },
    { label: "Enquiry Date", key: "enquiryDate" },
    { label: "Created By", key: "createdBy" }
  ];

  return (
    <Segment loading={!reportsLoaded} className='innerSegment'>
      <TopSection>
        <PageTitle>Enquiries</PageTitle>
        <DownloadReport
          data={data}
          headers={headers}
          loading={!reportsLoaded}
          className='download-btn'
          fileName='alltime'
        />
      </TopSection>

      <ReactTable
        data={data}
        columns={columns}
        defaultPageSize={10}
        filterable={true}
        className='-striped -highlight'
      />
    </Segment>
  );
}

const mapState = state => {
  return {
    auth: state.firebase.auth
  };
};

export default connect(mapState)(ViewEnquiries);
