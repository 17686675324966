import React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

const Brochures = styled.div`
  margin-top: 20px;
  a {
    display: block;
    margin-bottom: 10px;
    background-color: #008dcd;
    border-radius: 4px;
    padding: 10px 20px;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    transition: background-color 0.3s;
    transition: color 0.3s;
    :hover {
      background-color: #fff;
      color: #008dcd;
    }
  }
`;

const ProductDetailedFiles = ({ product }) => {
  return (
    <Brochures>
      <h3>Brochures</h3>
      {product.brochures &&
        product.brochures.map((brochure, index) => (
          <a
            key={index}
            href={brochure.pdfURL}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Icon name='file pdf outline' /> {brochure.title}
          </a>
        ))}
      {product.surgicalTechniques &&
        product.surgicalTechniques.map((surgicalechnique, index) => (
          <a
            key={index}
            href={surgicalechnique.pdfURL}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Icon name='file pdf outline' /> {surgicalechnique.title}
          </a>
        ))}
    </Brochures>
  );
};

export default ProductDetailedFiles;
