import React from "react";
import { Form, Label } from "semantic-ui-react";

const TextInput = ({
  input,
  width,
  type,
  label,
  placeholder,
  defaultValue,
  meta: { touched, error }
  // handleChange,
  // inputValue
}) => {
  return (
    <Form.Field>
      <label>{label}</label>
      <input
        {...input}
        placeholder={placeholder}
        type={type}
        value={input.value || defaultValue || ""}
        // onChange={handleChange}
        // value={inputValue}
      />
      {touched && error && (
        <Label basic color='red'>
          {error}
        </Label>
      )}
    </Form.Field>
  );
};

export default TextInput;
