import subDays from "date-fns/subDays";
import format from "date-fns/format";
///////////////////////////////////////////////////////////////////////////////
// Time Functions for logic
///////////////////////////////////////////////////////////////////////////////
export const getToday = () => {
  const date = new Date();

  return format(date, "yyyy/mm/dd");
};

export const getSevenDaysAgo = () => {
  const date = new Date();
  const sevenDaysAgo = subDays(date, 7);

  return format(sevenDaysAgo, "yyyy/mm/dd");
};

export const getThrityDaysAgo = () => {
  const date = new Date();
  const thirtyDaysAgo = subDays(date, 30);

  return format(thirtyDaysAgo, "yyyy/mm/dd");
};
