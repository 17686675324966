import React, { Component } from "react";
import { connect } from "react-redux";
import {
  combineValidators,
  isRequired,
  composeValidators,
  createValidator
} from "revalidate";
import { Form, Segment, Button, Label } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import TextInput from "../../../app/common/form/TextInput";
import { registerUser } from "../authActions";

const isValidBIGBraveEmail = createValidator(
  message => value => {
    if (value && value.split("@")[1] !== "bigbrave.co.za") {
      return message;
    }
  },
  "Invalid email address, only company emails can be registered"
);

const isValidZBEmail = createValidator(
  message => value => {
    if (value.split("@")[1] !== "zimmerbiomet.com") {
      return message;
    }
  },
  "Invalid email address, only company emails can be registered"
);

const validate = combineValidators({
  displayName: isRequired("displayName"),
  email: composeValidators(isRequired("email"), isValidZBEmail)(),
  password: isRequired("password")
});

class RegisterForm extends Component {
  render() {
    const {
      handleSubmit,
      registerUser,
      error,
      invalid,
      submitting
    } = this.props;
    return (
      <div>
        <Form
          size='large'
          autoComplete='off'
          onSubmit={handleSubmit(registerUser)}
        >
          <Segment>
            <Field
              name='displayName'
              type='text'
              component={TextInput}
              placeholder='Known As'
            />
            <Field
              name='email'
              type='text'
              component={TextInput}
              placeholder='Email'
            />
            <Field
              name='password'
              type='password'
              component={TextInput}
              placeholder='Password'
            />
            {error && (
              <Label basic color='red'>
                {error}
              </Label>
            )}
            <Button
              disabled={invalid || submitting}
              fluid
              size='large'
              color='teal'
            >
              Register
            </Button>
          </Segment>
        </Form>
      </div>
    );
  }
}

const actions = {
  registerUser
};

export default connect(
  null,
  actions
)(reduxForm({ form: "registerForm", validate })(RegisterForm));
