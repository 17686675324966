import React from "react";
import styled from "styled-components";

const Info = styled.div`
  margin-top: 10px;
  margin-right: 8%;
`;

const ProductDetailedInfo = ({ product }) => {
  return (
    <Info>
      <p>{product.description}</p>
    </Info>
  );
};

export default ProductDetailedInfo;
