import React, { Component } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const MyImage = ({ image }) => (
  <LazyLoadImage
    alt={image.alt}
    effect="blur"
    src={image.src} />
);

class TestComponent extends Component {
  render() {
    return (
      <div>
        <h1>Test</h1>
      </div>
    );
  }
}

export default TestComponent;
