import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Segment, Icon } from "semantic-ui-react";

const Card = styled(Link)`
  background-color: #ecececf7;
  border-radius: 8px;
  transition: box-shadow 0.3s;
  padding: 35px;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  h3 {
    text-align: center;
    padding: 10px;
    color: #1b1c1d;
  }
  i {
    color: #1b1c1d;
  }
  :hover {
    -webkit-box-shadow: 0px 3px 5px 0px rgba(199, 202, 207, 1);
    -moz-box-shadow: 0px 3px 5px 0px rgba(199, 202, 207, 1);
    box-shadow: 0px 3px 5px 0px rgba(199, 202, 207, 1);
  }
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const PageTitle = styled.div`
  background-color: #dee3e8;
  display: inline-block;
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
`;

class Dashboard extends Component {
  render() {
    return (
      <Segment className='innerSegment'>
        <PageTitle>Dashboard</PageTitle>
        <Cards>
          <Card to={`/catalogue`}>
            <Icon name='file alternate outline' size='big' />
            <h3>View Catalogue</h3>
          </Card>
          <Card to={`/enquiries`}>
            <Icon name='list alternate outline' size='big' />

            <h3>View Enquiries</h3>
          </Card>
          <Card to={`/enquire`}>
            <Icon name='plus square outline' size='big' />
            <h3>Create Enquiry</h3>
          </Card>
        </Cards>
      </Segment>
    );
  }
}

export default Dashboard;
