import React from "react";
import ReactDOM from "react-dom";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "./index.css";
import App from "./app/layout/App";
import ReduxToastr from "react-redux-toastr";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import ScrollToTop from "./app/common/util/ScrollToTop";
import { configureStore } from "./app/store/configureStore";
import DataStore from "./app/store/DataStore";
// import { loadProducts } from "./features/brochure/productActions";

const store = configureStore();

// store.dispatch(loadProducts());

const rootEl = document.getElementById("root");

let render = () => {
  ReactDOM.render(
    <DataStore>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <ReduxToastr
              position='bottom-right'
              transitionIn='fadeIn'
              transitionOut='fadeOut'
            />
            <App />
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    </DataStore>,
    rootEl
  );
};

if (module.hot) {
  module.hot.accept("./app/layout/App", () => {
    setTimeout(render);
  });
}

store.firebaseAuthIsReady.then(() => {
  render();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
