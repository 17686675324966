import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { Icon } from "semantic-ui-react";
import { getToday } from "../../../app/common/util/timeFunctions";
// import SimpleLoader from '../../../app/layout/SimpleLoader';

class DownloadReport extends Component {
  render() {
    const { data, headers, loading, fileName } = this.props;
    const today = getToday();

    // if (loading) return <SimpleLoader color="grey" />;

    return (
      <CSVLink
        data={data}
        headers={headers}
        className='download-btn'
        filename={`zimmer-biomet-report-${fileName}-${today}.csv`}
      >
        <Icon name='download' />
        <span>Download Report</span>
      </CSVLink>
    );
  }
}
export default DownloadReport;
